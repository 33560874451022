import React from 'react';
import { ButtonLink } from '@naf/button-link';
import { GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import AppIllustration from '../../../assets/images/Appreklame.svg?url';
import * as S from './Styles';

const AppAnnouncement = () => (
  <GridCol s="12" m="12" l="8" xl="8">
    <S.AppAnnouncementWrapper>
      <S.AppIllustration>
        <img src={AppIllustration} alt="Mobil illustrasjon" />
      </S.AppIllustration>
      <S.AppTextWrapper>
        <Text tag="h2" variant={TextVariant.ArticleTextLargeHeader}>
          Last ned NAF-appen og få medlemsfordelene i lomma!
        </Text>
        <ButtonLink href="https://medlemsapp.naf.no/last-ned" text="Få NAF-appen" />
      </S.AppTextWrapper>
    </S.AppAnnouncementWrapper>
  </GridCol>
);

export default AppAnnouncement;
