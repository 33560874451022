import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { Button } from '@naf/button';
import { Text } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import { breakpoints, spacing } from '@naf/theme';
import { TextList } from '@naf/text-list';

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.space16};

  & > a {
    @media (max-width: ${breakpoints.s}) {
      padding: 0 ${spacing.space16};
    }
  }
`;

export const CtaActivateButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledButtonLink = styled(ButtonLink)`
  margin-bottom: ${spacing.space32};
`;

export const ImageWrapper = styled.div`
  margin-top: 0;
  padding: 0 !important;
  position: relative;
  margin-top: ${spacing.space80};

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space24};
  }
`;

export const DiscountWrapper = styled.div`
  margin-bottom: ${spacing.space32};
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div`
  display: block;
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const StyledHeaderText = styled(Text)`
  margin-top: ${spacing.space48};
  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space32};
  }
`;

export const PartnerlogoWrapper = styled.div`
  display: inline-flex;
  position: absolute;
  background-color: ${nafColor.signature.white};
  border-radius: 2px;
  top: -32px;
  left: 0px;
  @media (max-width: ${breakpoints.m}) {
    bottom: -24px;
    right: 24px;
    top: auto;
    left: auto;
  }
  img {
    height: 32px;
    padding: 8px;
    @media (max-width: ${breakpoints.m}) {
      height: 24px;
      padding: 8px;
    }
  }
`;

export const DiscountInfoText = styled(Text)`
  margin-bottom: ${spacing.space32};
`;

export const DiscountText = styled(Text)`
  margin-bottom: ${spacing.space32};
  color: ${nafColor.primary.park};
  font-weight: 900;
`;

export const StyledTextList = styled(TextList)`
  margin-bottom: ${spacing.space32} !important;
`;

export const StyledButton = styled(Button)<{ applyLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.applyLeftMargin ? spacing.space16 : 0)};
`;

export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.space8};
`;

export const ImageFigure = styled.figure`
  margin: 0;
  width: calc(100% + 64px);
  position: relative;
  overflow: hidden;
  margin-left: -${spacing.space64};
  padding-top: 56.25%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: ${breakpoints.m}) {
    width: calc(100% + 48px);
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
  }
`;

export const Image = styled.img<{ hasImage?: boolean }>`
  width: 100%;
  object-fit: cover;
  display: block;
  ${(props) => (props.hasImage ? 'display: block' : 'display: none')}
`;

export const ImagePlaceholder = styled.img<{ hasImage?: boolean }>`
  width: 100%;
  max-width: 66.66666666666666%;
  object-fit: cover;
  height: 110%;
  margin-bottom: -18px;
  display: none;
  ${(props) => (props.hasImage ? 'display: none' : 'display: block')}
`;

export const BannerWrapper = styled.div`
  overflow: hidden;
  margin-bottom: ${spacing.space32};

  p {
    margin-bottom: ${spacing.space24};

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  // Styling for tablet
  @media (max-width: ${breakpoints.m}) and (min-width: ${breakpoints.s}) {
    > div {
      flex-direction: row;
      margin-right: ${spacing.space48};
      width: calc(100% - ${spacing.space48});
      margin-top: 0;
      > div:first-child {
        width: 70%;
        padding: 0;
        padding-right: ${spacing.space16};
      }
      > div:last-child {
        order: 0;
        min-height: calc(100% - 2 * ${spacing.space32});
        max-height: calc(100% - 2 * ${spacing.space32});
        max-width: 30%;
        top: 0;
        right: -${spacing.space48};
        padding-top: ${spacing.space24};
        > img {
          object-fit: cover;
        }
      }
    }
  }
`;

export const AppAnnouncementWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid ${nafColor.neutral.neutral3 || '#C1C1C1'};
  padding: ${spacing.space32} ${spacing.space24};
  margin-bottom: ${spacing.space32};
`;

export const AppTextWrapper = styled.div`
  h2 {
    margin-top: 0;
  }
`;

export const AppIllustration = styled.div`
  margin-right: ${spacing.space32};
  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    img {
      max-width: 67px;
    }
  }
`;
